.cover--deprecated {
  display: none;

  @media (--mq-from-tablet) {
    @mixin coverBackground;
    @mixin aspectRatioLegacy 23, 6, -1px;
    z-index: 0; /* behind layers */
    display: block;
  }

  /* Add a white gradient on cover */
  &::before {
    @mixin size 100%;
    @mixin absolutePosition;
    z-index: 2;
    pointer-events: none; /* prevent clickable element inside */
    content: '';
    background: var(--background-cover);
  }

  @mixin isDark {
    &::before {
      background: var(--background_darkmode-cover);
    }
  }

  *:only-child {
    @mixin size 100%;
    @mixin absolutePosition;
    z-index: 1;
    display: block;
  }
}
