.landing {
  position: relative;

  &--hasTopSpace {
    margin-top: 1.9375rem;
  }

  @mixin tvDevice {
    padding-bottom: 5rem;
  }

  &--overlapping_deprecated {
    @media (--mq-from-tablet) {
      margin-top: var(--margin_overlapping-cover-tablet-deprecated);
    }

    @media (--mq-from-laptop) {
      margin-top: var(--margin_overlapping-cover-laptop-deprecated);
    }

    @media (--mq-from-desktop) {
      margin-top: var(--margin_overlapping-cover-desktop-deprecated);
    }

    @media (--mq-from-large-desktop) {
      margin-top: var(--margin_overlapping-cover-large-desktop-deprecated);
    }

    @media (--mq-from-wide) {
      margin-top: var(--margin_overlapping-cover-wide-deprecated);
    }

    @mixin tvResize {
      margin-top: var(--margin_overlapping-cover-wide-deprecated);
    }
  }

  &__error {
    margin: 0 1rem;

    @media (--mq-from-tablet) {
      margin: 0 2rem;
    }

    @media (--mq-from-desktop) {
      margin: 0 4rem;
    }
  }

  &__strates {
    position: relative;

    @mixin tvDevice {
      padding-bottom: 5rem;
    }

    /* Strate Binder */
    & > div {
      margin-bottom: 1.25rem;

      @media (--mq-from-tablet) {
        margin-bottom: 1.5625rem;
      }

      @media (--mq-from-desktop) {
        margin-bottom: 1.875rem;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__heading {
    padding-right: var(--landing-sides-padding-mobile);
    padding-left: var(--landing-sides-padding-mobile);

    @media (--mq-from-tablet) {
      padding-right: var(--landing-sides-padding-tablet);
      padding-left: var(--landing-sides-padding-tablet);
    }

    @media (--mq-from-desktop) {
      padding-right: var(--landing-sides-padding-desktop);
      padding-left: var(--landing-sides-padding-desktop);
    }

    @mixin tvDevice {
      padding-right: var(--landing-sides-padding-tv);
      padding-left: var(--landing-sides-padding-tv);
    }

    &--deprecated {
      padding: 1.875rem 1rem 0.625rem 1rem;

      @media (--mq-from-tablet) {
        display: flex;
        justify-content: space-between;
        padding-right: 1.875rem;
        padding-left: 1.875rem;
      }

      @media (--mq-from-desktop) {
        padding-right: 3.75rem;
        padding-left: 3.75rem;
      }

      @media (--mq-from-large-desktop) {
        padding-right: 3.4375rem;
        padding-left: 3.4375rem;
      }

      @mixin tvDevice {
        justify-content: center;
      }
    }
  }

  &--detailV5 {
    .landing__error {
      margin: 0; /* padding is managed by detail container */
    }
    .landing__heading {
      padding: 0; /* padding is managed by detail container */
    }
  }
}
