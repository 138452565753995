:root {
  /* fonts */
  --font_hind: 'Hind', 'San Francisco', 'Roboto', 'Arial', sans-serif;
  --font_canal: 'Canal', 'San Francisco', 'Roboto', 'Arial', sans-serif;

  --base-font-style: normal;
  --base-font-weight: 400;

  /* sizes */
  --size_alert_height: 0px;
  --size_header_height: calc(4rem + var(--size_alert_height));
  --size_header_height-tv: 9.9375rem;
  --size_footer_height: 15.125rem;
  --size_mobileHeader_height: calc(6.375rem + var(--size_alert_height));
  --size_mobileHeader_height-withNav: calc(6.375rem + var(--size_alert_height));
  --size_mobileHeader_height-searchOpen: calc(
    4.125rem + var(--size_alert_height)
  );
  --size_mobileFooter_height: 11.125rem;
  --size_tv_content_width: 65rem;
  --size_detailv5_stickyTitle_height: 4rem;
  --size_detailv5_immersive_width: calc(
    100vw - 4rem
  ); /* 3.2px left & right padding */
  --size_detailv5_action_layout_bottom_height: 4.125rem;
  --size_detailv5_more_infos_max_width: 48.75rem;
  --viewport-resize-ratio: 1;
  --font-size-html: 100%; /* 100% = 16px default */

  /* margin */
  --margin_overlapping-cover-mobile: -4.375rem;
  --margin_overlapping-cover-logotype-mobile: -10.375rem;
  --margin_overlapping-cover-logotype-tablet: -9.5rem;
  --margin_overlapping-cover-laptop: -10.5rem;
  --margin_overlapping-cover-logotype-laptop: -14rem;
  --margin_overlapping-cover-desktop: -10.5rem;
  --margin_overlapping-cover-logotype-desktop: -18rem;
  --margin_overlapping-cover-largedesktop: -13rem;
  --margin_overlapping-cover-tv: -11.875rem;
  --margin_overlapping-cover-logotype-tv: -28rem;

  --poster-cover-height: 34.63542%; /* calculated from aspect-ratio: calc('1920:665' - 1px);*/
  --margin_overlapping-cover-laptop-deprecated: calc(
    17.3125rem - var(--poster-cover-height)
  );
  --margin_overlapping-cover-desktop-deprecated: calc(
    24.375rem - var(--poster-cover-height)
  );
  --margin_overlapping-cover-large-desktop-deprecated: calc(
    30.4375rem - var(--poster-cover-height)
  );
  --margin_overlapping-cover-wide-deprecated: calc(
    37.25rem - var(--poster-cover-height)
  );

  --landing-sides-padding-mobile: 1rem;
  --landing-sides-padding-tablet: 1.875rem;
  --landing-sides-padding-desktop: 4rem;
  --landing-sides-padding-tv: 5.625rem;

  --scroll-margin-skip-link: 9rem;

  --background-page: initial;

  /* showcase */
  --max-width-text: 40rem;
  --max-width-text-mobile: 21.8125rem;

  /* border radius */
  --border-radius-mini: 0.0625rem;
  --border-radius-small: 0.125rem;
  --border-radius-medium: 0.1875rem;
  --border-radius-big: 0.25rem;
  --border-radius-large: 0.375rem;
  --border-radius-extraLarge: 0.5rem;
  --border-radius-big-outline: 0.5rem;

  /* z-index for detailPage */
  --z-index-background: 1;
  --z-index-shadow: 5;
  --z-index-content: 10;
  --z-index-title: 20;
  --z-index-nav: 30;
}

[data-viewport~='resize720p'] {
  --viewport-resize-ratio: calc(720 / 1080);
  /* use font-size in pixel to support safari for resize */
  --font-size-html: 16px;
}
