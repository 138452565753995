.inputDropdown {
  --search-line-height: 3.6875rem;

  position: relative;
  width: 100%;

  &__searchChannels {
    height: 2.6875rem;
  }

  @mixin isDark {
    input {
      color: var(--color_darkmode-textcolor-primary);
      background: var(--color_darkmode-form-input-bg);

      &:focus {
        background: var(--color_darkmode-form-input-active-bg);
      }
    }
  }
}

.dropdown {
  position: absolute;
  top: calc(100% - 0.625rem);
  z-index: 3;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-height: 9.375rem;
  overflow: auto;
  color: var(--color_dropdown-light-fg);
  background-color: var(--color_dropdown-light-bg);
  border-top: 1px solid var(--color_dropdown-border-light);

  @mixin isDark {
    color: var(--color_dropdown-dark-fg);
    background-color: var(--color_dropdown-dark-bg);
  }

  &__searchChannels {
    top: calc(100%);
    max-height: calc(7 * var(--search-line-height)); /* 7 lines */
    overflow-x: hidden;
    border: 1px solid var(--color_dropdown-border-light);
    border-top: none;
    border-bottom: none;

    @mixin isDark {
      border: 1px solid var(--color_darkmode-line);
      border-top: none;
      border-bottom: none;
    }
  }

  &__line {
    flex: 0 0 100%;
    align-items: center;
    font-family: var(--font_hind);
    font-weight: 600;
    border-bottom: 1px solid var(--color_dropdown-border-light);

    &:hover,
    &:focus {
      color: var(--color_dropdown-item-light-fg-hover);
      cursor: pointer;
      background-color: var(--color_dropdown-item-light-bg-hover);
    }

    &:hover .dropdown__zapNumber,
    &:focus .dropdown__zapNumber {
      color: var(--color_dropdown-item-light-fg-hover);
    }

    @mixin isDark {
      border-bottom: 1px solid var(--color_dropdown-border-dark);

      &:hover,
      &:focus {
        color: var(--color_dropdown-item-dark-fg-hover);
        background-color: var(--color_dropdown-item-dark-bg-hover);
      }

      &:hover .dropdown__zapNumber,
      &:focus .dropdown__zapNumber {
        color: var(--color_dropdown-item-dark-fg-hover);
      }
    }

    &__address {
      max-width: 100%;
      height: auto;
      padding: 0.625rem 0.625rem 0.625rem 1.25rem;
    }

    &__searchChannels {
      display: flex;
      height: var(--search-line-height);
      font-size: 0.875rem;
    }

    &:first-of-type {
      [data-theme~='telecomitalia'] & {
        border-top: solid var(--color_dropdown-border-light) 1px;
      }
    }
  }

  &__street {
    display: block;
  }

  &__city {
    font-size: 0.875rem;
    color: hsl(180, 3%, 31%); /* #4d5252 */
  }

  &__logo {
    width: 4.375rem;
    padding-left: 0.8125rem;
  }

  &__channelInfo {
    display: flex;
    flex-direction: column;
    width: 13.5625rem;
    padding-left: 1.25rem;
  }

  &__zapNumber {
    font-size: 0.75rem;
    font-weight: normal;
    color: var(--color_dropdown-item-subtitle-light-fg);

    @mixin isDark {
      color: var(--color_dropdown-item-subtitle-dark-fg);
    }
  }
}
