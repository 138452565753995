@import '../../../../../themes/_variables.css';

.strate {
  /* Top offset used for the Strate's SkipLink:
  - prevents sticky header from hiding top of Strate content
  - leaves part of previous Strate's content visible as a visual breadcrumb */
  --strate-padding-top: 1.5625rem;
  @mixin tvDevice {
    --strate-padding-top: 1.25rem;
  }
  position: relative;
  padding-top: var(--strate-padding-top);
  padding-bottom: 1.25rem;

  outline: none;

  scroll-margin: calc(
      var(--scroll-margin-skip-link) - var(--strate-padding-top)
    )
    0 0 0;
  /* Graceful degradation for browsers without "scroll-margin" support, e.g.: Chrome<69 */
  @supports not (scroll-margin: 1px) {
    padding-top: var(--scroll-margin-skip-link);
    margin-top: calc(-1 * var(--scroll-margin-skip-link));
  }

  &--no-padding {
    padding: 0;
    scroll-margin: var(--scroll-margin-skip-link) 0 0 0;
    @supports not (scroll-margin: 1px) {
      padding-top: var(--scroll-margin-skip-link);
      margin-top: calc(-1 * var(--scroll-margin-skip-link));
    }
  }

  &__skip-link {
    position: absolute;
    left: 1rem;
    z-index: var(--z-index-4);
    max-width: calc(100vw - 3rem);
  }
}
