.errorMessage {
  &__inputError {
    --color_inputError-bg: var(--color_mine-shaft);

    [data-theme~='telecomitalia'] & {
      --color_inputError-bg: var(--color_gold-dark-primary);
    }

    display: flex;
    align-items: center;
    background-color: var(--color_inputError-bg);
    border-radius: var(--border-radius-big);
    @mixin tvDevice {
      padding: 1.25rem 2rem;
    }
  }

  &__icon {
    @mixin size 2.5rem;
    margin-right: 1.5rem;
  }

  &__text {
    font-size: 1rem;
    color: var(--color_text-primary);

    @mixin tvDevice {
      margin: 0;
      font-size: 1.6875rem;
    }
  }

  &__accent {
    margin: 0;
    color: var(--color_secondary);
  }
}
