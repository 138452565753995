.search {
  --color_border: var(--color_grey-350);

  @mixin isDark {
    --color_border: var(--color_darkmode-line);
  }

  [data-theme~='telecomitalia'] & {
    --color_border: transparent;
  }

  margin: 1.25rem 0;

  @media (--mq-from-tablet) {
    width: 18.875rem;
    margin: 0;
  }

  &__input {
    @mixin size 100%;
    padding: 0.625rem 1.25rem;
    font-family: var(--font_hind);
    color: var(--color_form-input);
    outline: none;
    background: var(--color_form-input-bg);
    border: 0.0625rem solid var(--color_border);
    border-radius: 3px;

    &::placeholder {
      color: var(--color_form-placeholder);
    }
  }
}
