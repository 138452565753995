.logoChannel {
  &__image {
    width: 100%;
    max-height: 7.5rem;

    &--isSearchChannelsDark {
      background-color: var(--color_grey-350-low-opacity);
      border-radius: var(--border-radius-small);
    }

    @mixin tvDevice {
      height: 100%;
    }
  }
}
