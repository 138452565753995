.headingIdentityV5 {
  margin-bottom: 1rem;

  @media (--mq-from-tablet) {
    padding-top: 5rem;

    &--withcover {
      padding-top: 0;
    }
  }

  @mixin tvDevice {
    padding-top: 2rem;
    margin-bottom: 2rem;

    &--withcover {
      @mixin tvDevice {
        padding-top: 0;
      }
    }
  }

  &__title {
    margin-bottom: 1rem;

    @media (--mq-from-desktop) {
      margin-bottom: 1.5rem;
    }

    @mixin tvDevice {
      margin-bottom: 2rem;
    }

    & > * {
      font-family: var(--font_canal);
      font-size: 1.5rem;
      line-height: 1.75rem;
      text-transform: uppercase;

      @media (--mq-from-desktop) {
        font-size: 2rem;
        line-height: 2.25rem;
      }

      @media (--mq-from-large-desktop) {
        font-size: 2.5rem;
        line-height: 2.75rem;
      }

      @mixin tvDevice {
        font-size: 3rem;
        line-height: 3.25rem;
      }
    }
  }

  /* we duplicate the className in order to force the use of this class in first (priority) */
  &__subtitle&__subtitle {
    @mixin lineClamp 3;
    padding-top: 0.5rem;
    color: var(--color_white-80);

    @media (--mq-from-tablet) {
      max-width: 50vw;
    }

    @mixin tvDevice {
      max-width: calc(100vw - 31.25rem);
    }

    & > * {
      font-size: 0.875rem;
      line-height: 1.125rem;

      @media (--mq-from-large-desktop) {
        font-size: 1.375rem;
        line-height: 1.75rem;
      }

      @mixin tvDevice {
        font-size: 1.875rem;
        line-height: 2.25rem;
      }
    }
  }

  &__logo {
    margin-bottom: 0.5rem;
    @mixin tvDevice {
      margin-bottom: 1rem;
    }

    &__image {
      width: 100%;
    }

    &--logotype {
      @mixin size 12rem, 6.75rem;

      @media (--mq-from-desktop) {
        @mixin size 15rem, 8.4375rem;
      }

      @mixin tvDevice {
        @mixin size 20rem, 11.25rem;
      }
    }

    &--logochannel {
      @mixin size 9rem, 6.75rem;

      @media (--mq-from-desktop) {
        @mixin size 13rem, 9.75rem;
      }

      @mixin tvDevice {
        @mixin size 15rem, 11.25rem;
      }
    }
  }

  &--withcover {
    .headingIdentityV5__logo {
      &--logotype {
        @media (--mq-from-desktop) {
          @mixin size 17.3125rem, 9.75rem;
        }

        @mixin tvDevice {
          @mixin size 30rem, 16.875rem;
        }
      }

      &--logochannel {
        @mixin tvDevice {
          @mixin size 22.5rem, 16.875rem;
        }
      }
    }
  }
}
