.cover--v5 {
  @mixin coverBackground;
  @mixin aspectRatioLegacy 16, 9, -1px;
  z-index: 0; /* behind layers */

  display: block;

  img {
    object-fit: cover;
  }

  @media (--mq-from-tablet) {
    @mixin aspectRatioLegacy 23, 6, -1px;
  }

  /* Add a white gradient on cover */
  &::before {
    @mixin size 100%;
    @mixin absolutePosition;
    z-index: 2;
    pointer-events: none; /* prevent clickable element inside */
    content: '';
    background: var(--background-cover);
  }

  @mixin isDark {
    &::before {
      background: var(--background_darkmode-cover);
    }
  }

  *:only-child {
    @mixin size 100%;
    @mixin absolutePosition;
    z-index: 1;
    display: block;
  }
}
