.identityV5 {
  &--overlapping {
    margin-top: var(--margin_overlapping-cover-mobile);

    @media screen and (orientation: landscape) and (--mq-from-laptop) {
      margin-top: var(--margin_overlapping-cover-laptop);
    }

    @media screen and (orientation: landscape) and (--mq-from-desktop) {
      margin-top: var(--margin_overlapping-cover-desktop);
    }

    @media screen and (orientation: landscape) and (--mq-from-large-desktop) {
      margin-top: var(--margin_overlapping-cover-largedesktop);
    }

    @mixin tvDevice {
      margin-top: var(--margin_overlapping-cover-tv);
    }

    &__logo {
      margin-top: var(--margin_overlapping-cover-logotype-mobile);

      @media screen and (orientation: landscape) and (--mq-from-tablet) {
        margin-top: var(--margin_overlapping-cover-logotype-tablet);
      }

      @media screen and (orientation: landscape) and (--mq-from-laptop) {
        margin-top: var(--margin_overlapping-cover-logotype-laptop);
      }

      @media screen and (orientation: landscape) and (--mq-from-desktop) {
        margin-top: var(--margin_overlapping-cover-logotype-desktop);
      }

      @mixin tvDevice {
        margin-top: var(--margin_overlapping-cover-logotype-tv);
      }
    }
  }
}
